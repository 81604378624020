import styled from "styled-components";

export const SubFooterTitle = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
`

export const SubFooterContent = styled.div`
  font-size: 16px;
  line-height: 26px;
`

export const Locations = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 70px;
  font-size: 21px;
  color: var(--text-color);
`
