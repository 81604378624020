import styled from "styled-components";
import {GrLinkedinOption} from "react-icons/gr";
import {Link} from "gatsby";

export const Container = styled.div`
  position: relative;
  border: 1px solid #e6e6e6;
  transition: 0.3s;

  & .main {
    opacity: 1;
    animation-name: fade-in-bottom;
    animation-duration: 0.6s;
  }

  & .secondary {
    position: absolute;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    color: var(--primary-color);
    font-size: 14px;
    animation-name: fade-out-bottom;
    animation-duration: 0.6s;
  }
  
  & .secondary a {
    outline: none;
  }
  
  & .linked-in {
    opacity: 0;
  }

  &:hover {
    box-shadow: 0 10px 28px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 7%);
    
    & .main {
      opacity: 0;
      animation-name: fade-out-top;
      animation-duration: 0.6s;
    }

    & .secondary {
      top: 20px;
      opacity: 1;
      pointer-events: auto;
      animation-name: fade-in-top;
      animation-duration: 0.6s;
    }

    & .linked-in {
      opacity: 1;
      animation-name: fade-in;
      animation-duration: 0.9s;
    }
  }
  
  @keyframes fade-out-top {
    from {
      opacity: 1
    }

    to {
      opacity: 0;
      transform: translateY(-150%);
    }
  }

  @keyframes fade-in-bottom {
    from {
      opacity: 0;
      transform: translateY(-150%);
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fade-in-top {
    from {
      top: 76px;
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fade-out-bottom {
    from {
      top: 20px;
      opacity: 1;
    }

    to {
      top: 76px;
      opacity: 0;
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Image = styled.img`
  position: relative;
  z-index: 2;
`

export const Description = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  height: 96px;
  padding: 20px;
  
  & .name {
    line-height: 32px;
    font-size: 18px;
    color: var(--subtitle-color);
  }
  
  & .position {
    font-size: 13px;
    color: var(--text-color);
  }
`

export const SocialMediaButton = styled(Link)``;

export const LinkedinIcon = styled(GrLinkedinOption)`
  position: absolute;
  margin-top: -50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 30px;
  height: 30px;
  background-color: #ffffffab;
  color: #545454;
  display: flex;
  padding: 5px;
  border-radius: 50%;
  overflow: visible;
  z-index: 3;
  cursor: pointer;
  transition: 0.4s ease;

  &:hover{
    color: #fff;
    background-color: #0077b5;
  }
`;



