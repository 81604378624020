import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import AboutUs from "../components/OurCompany/AboutUs/AboutUs";

import Favicon from "../images/Favicon.png";

const AboutUsPage = () =>{
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About Us - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="keywords" content="fleet management"/>
                <meta name="description"
                      content="Learn about Drivosity's innovative GPS tracking solutions for last-mile delivery. Our story, values, and diverse team are focused on driving safety and productivity within first-party delivery with GPS-equipped car toppers."/>
            </Helmet>
            <Layout component={<AboutUs/>}/>
        </>
    )
}

export default AboutUsPage
