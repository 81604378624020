/**
 * DrvQuote is shared Component which is used on common page of this Website.
 * Contains image, name, position and quote of some person.
 *
 * To use this component you need to specify image, name, position and quote.
 **/

import React from "react";

import {Container, Content, Description, Author, Position} from './DrvQuote.styles';

const DrvQuote = ({image, name, position, quote}) => {
    return (
        <Container className='container mx-auto'>
            <Content className='flex flex-row flex-wrap lg:flex-nowrap px-5 md:px-20 lg:space-x-4'>
                <div className='flex basis-6/12 lg:basis-5/12 xl:basis-4/12 lg:pt-5 items-end justify-center'>
                    <img src={image} alt="Drivosity employee profile-photo" />
                </div>

                <Author className='block lg:hidden flex flex-col basis-6/12 justify-end'>
                    {name}
                    <Position className='flex items-center'>
                        {position}
                    </Position>
                </Author>

                <div className='flex flex-col basis-full lg:basis-7/12 xl:basis-8/12 py-5 justify-center'>
                    <Description>
                        “{quote}”
                        <Author className='hidden lg:block justify-center'>
                            {name}
                            <Position className='flex items-center'>
                                {position}
                            </Position>
                        </Author>
                    </Description>
                </div>
            </Content>
        </Container>
    );
}

export default DrvQuote;
