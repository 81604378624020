import React from "react";
import {FiMail} from "react-icons/fi";

import {Container, Image, Description, LinkedinIcon} from "./DrvCardTeam.styles";

const DrvCardTeam = ({team}) => {
    return (
        <>
            {
                team.map((item, index) => (
                    <div key={index}
                        role="button"
                        tabIndex="0"
                        className="flex flex-col p-2"
                        onMouseOver={e => {
                            e.target.src = item.secondImage;
                        }}
                        onMouseOut={e => {
                            e.target.src = item.firstImage;
                        }}
                        onFocus={() => {}}
                        onBlur={() => {}}
                    >
                        <Container>
                            <Image src={item.firstImage} alt="Drivosity employee profile-photo"/>
                            <a href={item.linkedin} target="_blank" rel="noreferrer" >
                                <LinkedinIcon className='linked-in' />
                            </a>
                            <Description>
                                <div className='main'>
                                    <div className='name'>{item.name}</div>
                                    <div className='position'>{item.position}</div>
                                </div>
                                <div className='secondary space-x-1'>
                                    <FiMail />
                                    <a href={"mailto:" + item.email}>{item.email}</a>
                                </div>
                            </Description>
                        </Container>
                    </div>
                ))
            }
        </>
    )
}

export default DrvCardTeam;
